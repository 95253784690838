.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.LoginPage {
  max-width: 300px;
  margin: 200px auto; 
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.LoginPage h2 {
  margin-top: 0;
}

.LoginPage input[type="text"],
.LoginPage input[type="password"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.LoginPage button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.Home {
  text-align: center;
  padding: 20px;
}

.Home h2 {
  margin-top: 20px;
}

.nav-link {
  margin: 10px;
  text-decoration: none;
  font-weight: bold;
  color: #007bff;
}

.nav-link:hover {
  text-decoration: underline;
}


/* --------------------------search bar------------------------------------ */

.filter-form {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PortalTable {
  width: 100%;
  border-collapse: collapse;
}

.PortalTable th, .PortalTable td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.PortalTable th {
  background-color: #f2f2f2;
}

.PortalTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.PortalTable tr:hover {
  background-color: #ddd;
}

.pagination {
  margin-top: 20px;
}

.pagination ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  margin-right: 5px;
} 

.pagination button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.pagination button.active {
  background-color: #ccc;
  pointer-events: none;
}


.pagination {
  text-align: center;
  margin-top: 20px;
}

.page-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007BFF;
  color: #fff;
  border: 1px solid #007BFF;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  margin: 0 5px;
}

.page-link:hover {
  background-color: #0056b3;
}

.page-link:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-link:first-child {
  margin-left: 0;
}

.page-link:last-child {
  margin-right: 0;
}

.loader {
  text-align: center;
  font-size: 1.5rem;
  margin: 200px;
  color: #007BFF;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.filter-form {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-form select {
  width: 20%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  cursor: pointer;
  transition: border-color 0.3s;
  margin-right: 10px;
}

.filter-form select:last-child {
  margin-right: 10; 
}

.filter-form select:focus {
  outline: none;
  border-color: #007bff;
}

.filter-form select option {
  padding: 20px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.filter-form select:hover {
  border-color: #999;
}

.filter-form select:active {
  border-color: #555;
}


.LogoutButton {
  position: absolute;
  top: 20px;
  right: 100px; 
  padding: 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;
}





/* ----------------------------------------last updated css------------------------------ */




.filter-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}


.filter-form select {
  width: 20%;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.filter-form select:focus {
  outline: none;
  border-color: #007bff;
}

.filter-form select option {
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.filter-form select:hover {
  border-color: #999;
}

.filter-form select:active {
  border-color: #555;
}


.filter-form input[type="text"] {
  width: 20%;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  transition: border-color 0.3s;
}

.filter-form input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
}


.filter-form input::placeholder {
  color: #999;
}

.filter-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.filter-form button:hover {
  background-color: #0056b3;
}


/* -------------------------date filter design--------------------------- */

.filter-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 45px;
}

.filter-form select, .filter-form input[type="date"] {
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
}


.filter-form input[name="propertyCode"] {
  flex: 2;
}


.filter-form button {
  padding: 8px 16px;
  background-color: #007bff; 
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.filter-form button:hover {
  background-color: #0056b3; 
}





/* -------------------------font-size medium---------------------------------------- */


.PortalTable {
  width: 100%;
  border-collapse: collapse;
}

.PortalTable th,
.PortalTable td {
  padding: 8px; 
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px; 
}

.PortalTable th {
  background-color: #f2f2f2;
}

.PortalTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.PortalTable tr:hover {
  background-color: #ddd;
}



/* --------------------------updated css------------------------ */


.PortalTable {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0; 
}

.PortalTable th,
.PortalTable td {
  padding: 6px; 
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px; 
}

.PortalTable th {
  background-color: #f2f2f2;
}

.PortalTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.PortalTable tr:hover {
  background-color: #ddd;
}



/* ------------------------updated filter and search date design ----------------------------- */



.filter-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 40px;
}


.filter-form select,
.filter-form input[type="date"],
.filter-form input[type="text"] {
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; 
  flex: 1;
}


.filter-form input[name="propertyCode"] {
  flex: 2;
}


.filter-form button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; 
  transition: background-color 0.3s;
}


.filter-form button:hover {
  background-color: #0056b3;
}

.filter-form input[type="text"],
.filter-form input[type="date"] {
  flex: 1;
}


.PortalTable {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
}

.PortalTable th,
.PortalTable td {
  padding: 6px; 
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px; 
}

.PortalTable th {
  background-color: #f2f2f2;
}

.PortalTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.PortalTable tr:hover {
  background-color: #ddd;
}


.pagination {
  text-align: center;
  margin-top: 20px;
}

.page-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007BFF;
  color: #fff;
  border: 1px solid #007BFF;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  margin: 0 5px;
}

.page-link:hover {
  background-color: #0056b3;
}

.page-link:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-link:first-child {
  margin-left: 0;
}

.page-link:last-child {
  margin-right: 0;
}


/* --------------------------------JOb Detail page css--------------------------------------- */


.JobDetail {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.loader {
  text-align: center;
  font-size: 16px;
  padding: 20px;
  background-color: #fff;
}

.job-details-table {
  width: 100%;
}

.label {
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, td, th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* ---------------------------job details css testing 3d------------------------------ */

/* Container */
.JobDetail {
  text-align: center;
  margin: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Title */
h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
}

/* Loading Spinner */
.loader {
  font-size: 16px;
  margin: 20px;
  position: relative;
  display: inline-block;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #0077b6;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Job Details Table */
.job-details-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.job-details-table th {
  background-color: #0077b6;
  color: #fff;
  padding: 12px;
  text-align: left;
}

.job-details-table td {
  background-color: #f9f9f9;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.job-details-table tbody tr:nth-child(even) {
  background-color: #e5e5e5;
}

.job-details-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* -------------------------------------home page 3d testing design--------------------- */

/* Container */
.Home {
  margin: 20px;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Title */
h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #0077b6;
  text-transform: uppercase;
}

/* Loading Spinner */
.loader {
  font-size: 16px;
  margin: 20px;
  position: relative;
  display: inline-block;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #0077b6;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Filter Form */
.filter-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filter-form select,
.filter-form input {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-form input[type="date"] {
  width: 180px;
}

/* Portal Table */
.PortalTable {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  border: 1px solid #ddd;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.PortalTable th {
  background-color: #0077b6;
  color: #fff;
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.PortalTable td {
  background-color: #f9f9f9;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.PortalTable tbody tr:nth-child(even) {
  background-color: #e5e5e5;
}

.PortalTable tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.PortalTable th, .PortalTable td {
  min-width: 120px;
}

/* Pagination */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.page-link {
  background-color: #0077b6;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-link:hover {
  background-color: #005a8d;
}

